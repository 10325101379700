import { useEffect, useState } from "react";

export const useScript = (url: string): boolean => {
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    const script: HTMLScriptElement = document.createElement("script");

    script.src = url;
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = (event) => console.warn(event.toString());

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return isScriptLoaded;
};