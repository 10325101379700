import { createContext, useContext } from "react";

type AudioOutputSettings = {
  deviceId: string | null;
  muted: boolean;
  setDeviceId: (deviceId: string) => void;
  setMuted: (muted: boolean) => void;
};

export const AudioOutputContext = createContext<AudioOutputSettings>({
  deviceId: null,
  muted: false,
  setDeviceId: function (deviceId) {
    this.deviceId = deviceId;
  },
  setMuted: function (muted) {
    this.muted = muted;
  },
});

export const useAudioOutput = () => useContext(AudioOutputContext);
