import React, { useEffect, useRef, useState } from "react";
import { useScript } from "./useScript";

import "./App.css";

type Props = {
  config: any;
  roomName: string;
  signerURL: string;
};

const GruveoEmbed: React.FC<Props> = (props) => {
  const { config, roomName, signerURL } = props;
  const embedRef = useRef<HTMLDivElement | null>(null);
  const isScriptLoaded = useScript("https://www.gruveo.com/embed-api/");
  const [isGruveoLoaded, setIsGruveoLoaded] = useState<boolean>(false);

  useEffect(() => {
    //@ts-ignore
    if (window.Gruveo) setIsGruveoLoaded(true);
    window.addEventListener("gruveo-api-loaded", () => setIsGruveoLoaded(true));
  }, []);

  useEffect(() => {
    if (isGruveoLoaded && isScriptLoaded && embedRef?.current && config && roomName && signerURL) {
      //@ts-ignore
      const embed = new window.Gruveo.Embed(embedRef.current?.id, config);

      embed
        .on("ready", () => embed.call(roomName, true))
        //@ts-ignore
        .on("stateChange", ({ state, callDuration }) => {
          if (state === "ready") console.log("Gruveo embed is ready.");
          else if (state === "call") embed.toggleRoomLock(true);
        })
        // @ts-ignore
        .on("requestToSignApiAuthToken", async ({ token }) => {
          try {
            const res = await fetch(signerURL, { method: "POST", body: token });
            if (res.status === 200) {
              const signature = await res.text();
              embed.authorize(signature);
            } else {
              throw new Error(`Unexpected server response status code "${res.status}".`);
            }
          } catch (err) {
            console.error(`Error signing API Auth token: ${err.message}`);
            embed.end();
          }
        });
    }
  }, [config, embedRef, isGruveoLoaded, isScriptLoaded, roomName, signerURL]);

  return (
    <>
      {!config || !roomName ? (
        <strong>A configuration object and room name are required to start Jitsi call.</strong>
      ) : (
        <div className="embed-container">
          <div id="gruveo-embed" ref={embedRef} className="embed"></div>
        </div>
      )}
    </>
  );
};

export default GruveoEmbed;
