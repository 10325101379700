export interface Devices {
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
}

export interface InputOverrides {
  audio: {
    volume: number;
  };
}

export enum Perspective {
  presenter = "presenter",
  participant = "participant",
}

export type ConnectionErrorName =
  | "RoomNotFoundError"
  | "UnableToCreateRoomError";

export type MediaErrorName =
  | "NotAllowedError"
  | "NotFoundError"
  | "NotReadableError"
  | "OverconstrainedError"
  | "TypeError";

export type MessageName = "Connecting" | "ConnectionErrorPrefix";
