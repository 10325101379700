import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Box)(() => ({
  alignItems: "center",
  backgroundColor: "black",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  left: 0,
  maxHeight: "100%",
  maxWidth: "100%",
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 15,
  "& > *": {
    color: "white",
    margin: 2.5,
  },
}));
